.miniGame {
  padding-top: 64px;
  .heading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 140px;
    margin-top: 0;
  }
  .heading-box-contain {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .heading-box h1 {
    display: block;
    margin: 0 auto;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    padding-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 1023px) {
    .heading-box h1 {
      font-size: 32px;
    }
  }
  @media (max-width: 480px) {
    .heading-box h1 {
      font-size: 24px;
    }
  }
  .heading-box h1:after {
    content: "";
    display: block;
    width: 216px;
    height: 1px;
    background: url("../../assets/images/header_line.svg") no-repeat center
      center;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .heading-box h1 strong {
    font-weight: 700;
  }
  .heading-box h1:after {
    content: "";
    display: block;
    width: 216px;
  }

  .section-games {
    width: 100%;
    height: 100%;
    border: 1px solid #424242;
    padding: 0;
    margin: auto;
    flex: 1;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    @media (max-width: 480px) {
      border: none;
    }

    .menu-games {
      padding: 15px;

      @media (max-width: 1023px) {
        flex: 1;
      }

      .item:hover {
        background-color: #000;
      }
      .item {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        cursor: pointer;
        border-top: 1px solid #424242;
        align-items: center;
        padding: 10px;
        color: #bdbdbd;
        font-size: 15px;
        transition: 0.3s ease-in;

        &-left {
          width: 10%;
        }
        &-center {
          width: 75%;
        }
        &-right {
          width: 15%;
        }

        @media (max-width: 675px) {
          justify-content: space-between;
        }

        .team {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          @media (min-width: 600px) {
            &-left {
              display: flex;
              justify-content: flex-end;
              width: 200px;
              align-items: center;
            }
            &-right {
              display: flex;
              width: 200px;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
        span {
          color: #bdbdbd;
          font-size: 15px;
        }
        img {
          margin-right: 10px;
        }

        .btn-predict {
          display: flex;
          justify-content: center;
          width: 100px;
          background-color: #4caf50 !important;
          border-color: #4caf50 !important;
          border-radius: 12px;
          font-size: 12px;
          height: 24px;
          align-items: center;
          cursor: pointer;
          color: #333;

          @media (max-width: 480px) {
            display: none;
          }
        }
      }
      .item.active {
        background: #1b2340;
      }
    }
    .detail-games {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      height: 100%;
      padding: 2%;
      flex: 1;

      .table-options.option-effect {
        transform: scale(var(--scale));
        animation: pulse infinite 1s;
      }

      @keyframes pulse {
        0% {
          --scale: 1;
          --clr: red;
        }
        50% {
          --scale: 1.1;
          --clr: blue;
        }
        100% {
          --scale: 1;
          --clr: red;
        }
      }

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      &-banner {
        flex: 6;
        padding: 0 20px;
      }

      @media (max-width: 1023px) {
        &-banner {
          flex: 1;
          margin-bottom: 30px;
        }
      }

      &-description {
        flex: 6;

        @media (max-width: 1023px) {
          flex: 1;
        }
        .btn-submit {
          height: 40px;
          width: 200px;
          margin: 12px;
          border-radius: 4px;
          font-size: 20px;
          align-items: center;
          cursor: pointer;
          text-align: center;
          background: linear-gradient(
            221.15deg,
            #bd8727,
            #ffd574 49.02%,
            #feca50 62.02%,
            #bd8727 101.47%
          );
          color: #fff;
          border: none;
          font-size: 14px;
        }

        .disable-btn {
          filter: grayscale(100%) !important;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
        }

        .claim-success {
          filter: grayscale(100%) !important;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
        }

        @media (max-width: 675px) {
          .btn-submit {
            width: 146px;
            font-size: 12px;
          }
        }

        .description {
        }
        .MatchGame {
          .match-result {
            font-size: 60px;
            color: rgb(21, 195, 236);
            text-align: center;
            font-weight: 400;
          }
        }
        .your-predict {
          .description {
            text-align: left;
          }

          .slider-option {
            display: flex !important;
            justify-content: center;
            align-items: center;
          }

          .box-result-match {
            display: flex;
            height: 36px;
            color: #fff;
            background-color: #166026;
            margin-top: 15px;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
