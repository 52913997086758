:root {
  // background
  // --primary-color: #1e1e1e;
  --primary-color: #00050a;
  --white-color: #ffffff;
  --bs-gutter-x: 10px;

  // dimension
  --tiny: 6px;
  --small: 10px;
  --medium: 14px;
  --large: 16px;
  --big: 24px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  font-family: Montserrat, sans-serif;

  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1320px;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
  }

  @media (max-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
  }
  @media (max-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
  }
  @media (max-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
  }
  @media (max-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

* ::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}
* ::-webkit-scrollbar-thumb {
  background: #f8b017;
  width: 0;
  height: 25%;
}
* ::-webkit-scrollbar-track {
  width: 12px;
  background: #fff;
  border-left: 0 solid #fff;
  border-right: 0 solid #fff;
}

body {
  //background: #1d1d1d !important;
  background: #00050a !important;
  color: #fff !important;
}

body.modal-container {
  overflow: hidden;
}

.divider {
  height: 1px;
  width: "100%";
  background: rgb(253, 234, 234);
}

.bold {
  font-weight: bold !important;
}

.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}

.flex_row_left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}

.flex_row_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}

.flex_row_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}

.flex_row_start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.flex_row_end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

// text
.text-tiny {
  color: var(--text-primary-color);
  font-size: 10px !important;
  font-weight: 400;
  line-height: 16px;
}

.text-small {
  color: var(--text-primary-color);
  font-size: 13px !important;
  font-weight: 400;
}

.text-medium {
  color: var(--text-primary-color);
  font-size: 15px !important;
  font-weight: 400;
}

.text-large {
  color: var(--text-primary-color);
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
}

// text-color
.white {
  color: var(--white-color);
}

.red {
  color: red;
}

.gray {
  color: #bdbdbd;
}

.yellow {
  color: #fbc02d;
}

// margin
.mb-big {
  margin-bottom: var(--big) !important;
}

.mb-large {
  margin-bottom: var(--large) !important;
}

.mb-medium {
  margin-bottom: var(--medium) !important;
}

.mb-small {
  margin-bottom: var(--small) !important;
}

.mb-tiny {
  margin-bottom: var(--tiny) !important;
}

//margin right
.mr-big {
  margin-right: var(--big) !important;
}

.mr-large {
  margin-right: var(--large) !important;
}

.mr-medium {
  margin-right: var(--medium) !important;
}

.mr-small {
  margin-right: var(--small) !important;
}

.mr-tiny {
  margin-right: var(--tiny) !important;
}

.mt-big {
  margin-top: var(--big) !important;
}

.mt-large {
  margin-top: var(--large) !important;
}

.mt-medium {
  margin-top: var(--medium) !important;
}

.mt-small {
  margin-top: var(--small) !important;
}

.mt-tiny {
  margin-top: var(--tiny) !important;
}

.margin-horizontal-large {
  margin: 0 30px;
}
