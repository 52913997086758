.mini-game {
  .heading-box {
    display: flex;
    align-items: center;
    margin-top: 75px;
    justify-content: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 140px;
  }
  .heading-box-contain {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .heading-box h1 {
    display: block;
    margin: 0 auto;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    padding-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 1023px) {
    .heading-box h1 {
      font-size: 32px;
    }
  }
  @media (max-width: 480px) {
    .heading-box h1 {
      font-size: 24px;
    }
  }
  .heading-box h1:after {
    content: "";
    display: block;
    width: 216px;
    height: 1px;
    background: url("../../assets/images/header_line.svg") no-repeat center
      center;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .heading-box h1 strong {
    font-weight: 700;
  }
  .heading-box h1:after {
    content: "";
    display: block;
    width: 216px;
  }

  .list-mini-game {
    margin-top: 60px;
    .box-item {
      display: flex;
      gap: 60px;
      margin-bottom: 48px;
      flex-wrap: wrap;

      .item:hover {
        .thumb:after {
          opacity: 0.6;
        }
        .thumb {
          .btn {
            opacity: 1;
          }
        }
      }

      .item {
        width: calc(33.33333% - 40px);

        @media (max-width: 1023px) {
          width: calc(50% - 30px);
        }

        @media (max-width: 480px) {
          width: calc(100%);
        }

        .thumb {
          display: block;
          position: relative;
          margin-bottom: 16px;
          cursor: pointer;
          img {
            width: 100%;
          }

          .btn {
            height: 44px;
            width: 140px;
            border: 1px solid #fbc02d;
            color: #fbc02d;
            font-weight: 600;
            padding: 10px 24px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            transition: 0.3s ease-in;
            opacity: 0;
          }
          .btn:hover {
            background-color: #fbc02d;
            color: #fff;
          }
        }

        .thumb:after {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0;
          transition: 0.3s ease-in;
          background: #000;
        }

        .name {
          display: block;
          font-weight: 600;
          color: #fff;
          margin-bottom: 12px;
        }
        .deadline {
          font-size: 14px;
          color: #bdbdbd;
        }
        .time {
          margin-left: 6px;
          font-size: 16px;
          font-weight: bold;
          color: red;
        }
      }
    }
  }
}
