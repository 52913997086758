.table-option {
  position: relative;

  .place-closed {
    display: flex;
    position: absolute;
    background-color: #333;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0.8;
    z-index: 1;
    border-radius: 8px;
    img {
      width: 100px;
      height: 100px;
      object-fit: fill;
    }
  }

  .item-option {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #2e2e2e;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 2px 0;
    width: 20%;
    margin: 2.5%;
    cursor: pointer;
    transition: 0.3s ease-in;
  }

  .item-option:hover {
    background: #000;
  }

  .item-option-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #2e2e2e;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px 0;
    font-size: 16px;
    width: 23%;
    cursor: pointer;
    transition: 0.3s ease-in;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .item-option-2.active {
    background-color: #fbc02d !important;
    color: #333 !important;
    font-weight: bold;
  }

  .item-option-2:hover {
    background: #000;
  }

  .item-option.active {
    background-color: #fbc02d !important;
    color: #333 !important;
    font-weight: bold;
  }

  .item-option-2.disable {
    background-color: #9d9c9c;
  }

  .item-option.disable {
    background-color: #9d9c9c;
  }
}
