.header {
  display: flex;
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  padding: 0 45px;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #00050a;
  transform: translateY(0px);

  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;

  @media (max-width: 675px) {
    padding: 0 10px;
    ol,
    ul {
      padding-left: 0;
    }
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  .main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .main-menu ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
  }

  .main-menu ul li a {
    color: #fff;
    display: inline-block;
    padding: 6px 10px;
    position: relative;
    text-decoration: none;
  }
  .main-menu ul li a strong {
    color: #fbc02d;
  }

  .main-menu ul li a:before,
  .main-menu ul li a:after {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background: #fff;
    opacity: 0;
    position: absolute;
    transition: ease-in 0.3s;
  }

  .main-menu ul li a:before {
    left: 40px;
    top: 0;
  }
  .main-menu ul li a:after {
    right: 40px;
    bottom: 0;
  }
  .main-menu ul li a:hover:before,
  .main-menu ul li a:hover:after {
    opacity: 1;
  }
  .main-menu ul li a:hover:before {
    left: -5px;
  }
  .main-menu ul li a:hover:after {
    right: -5px;
  }
  .main-menu ul li .btn-outline-primary {
    height: 44px;
    padding: 8px 36px;
    border: 2px solid #fbc02d;
    color: #fbc02d;
    border-radius: 0;
    margin-left: 20px;
  }
  .main-menu ul li .btn-outline-primary:before,
  .main-menu ul li .btn-outline-primary:after {
    display: none;
  }
  .main-menu ul li .btn-outline-primary:hover {
    background: #fbc02d;
    color: #212121;
  }

  .main-menu .menu-pc {
    li {
      padding: 0 12px;
    }
  }
  @media (max-width: 1279px) {
    .main-menu .menu-pc {
      display: none;
    }
  }
  .main-menu .menu-mobile {
    display: none;
    /* margin-right: 10px; */
    li {
      padding: 0 8px;
    }
  }
  @media (max-width: 1279px) {
    .main-menu .menu-mobile {
      display: flex;
    }
  }
  .main-menu .menu-mb .menu_mb_btn {
    display: block;
    margin-left: 20px;
  }
  @media (max-width: 480px) {
    .main-menu .menu-mb .buy {
      display: none;
    }
  }

  /**
    Navigation mobile
  */

  .nav-wrapper {
    //display: none;
    display: inline-block;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    transition: all linear 0.25s;
    background: transparent;
    z-index: 9999;
  }

  .nav-wrapper.nav-active {
    transform: translateX(0);
    background: rgba(0, 0, 0, 1);
    .nav-mobile {
      display: block;
    }
  }

  .nav-mobile {
    display: none;
    list-style: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 12;
    overflow: auto;
    padding: 40px;
    &-item {
      padding: 8px 0;
    }
    background: rgba(0, 0, 0, 0.8);
  }

  .nav-mobile a {
    width: 100vw;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    cursor: pointer;
  }

  .nav-mobile a:hover {
    background-color: #6e6d6d;
  }

  .connect-wallet-btn {
    height: 36px;
    min-width: 64px;
    padding: 0 10px;
    background: #00050a;
    color: #ffffff;
    border: thin solid currentColor;
    border-radius: 4px;
    @media (max-width: 675px) {
      font-size: 10px;
      font-weight: 700;
    }
  }
  .btn-address {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: rgb(248, 176, 23);
    font-size: 11px;
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    border-radius: 28px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    .button {
      cursor: pointer;
      padding: 20px 2px;
    }
  }

  /* Dropdown Content (Hidden by Default) */

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -60px;
    top: 35px;
    text-align: left;
    border-radius: 4px;
  }

  /* Links inside the dropdown */

  .dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */

  .dropdown-content div:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */

  .dropdown-content.active {
    display: block;
    cursor: pointer;
  }
}
