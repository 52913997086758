.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .modal-wrapper {
    position: relative;
    width: 80vw;
    background: #000000 !important;
    box-shadow: rgba(223, 219, 34, 0.4) 4px 4px, rgba(255, 220, 19, 0.3) 8px 8px,
      rgba(240, 46, 170, 0.1) 12px 12px, rgba(240, 46, 170, 0.05) 16px 16px !important;
    border-radius: 6px;
    max-width: 500px;
    padding: 20px 30px;

    .btn-close {
      position: absolute;
      top: 20px;
      right: 18px;
      background-color: #e0e0e0;
      border-color: #e0e0e0;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      cursor: pointer;
    }

    .btn-metamask {
      height: 54px;
      padding: 0 12px;
      background: #f5d0b1;
      color: #282c37;
      cursor: pointer;
      border-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
    }
    .btn-trust {
      height: 54px;
      padding: 0 12px;
      cursor: pointer;
      background: linear-gradient(
          0deg,
          rgba(59, 153, 251, 0.1),
          rgba(59, 153, 251, 0.1)
        ),
        #ffffff;
      color: #282c37;
      border-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
    }
  }

  .choose-wallet-connect {
    padding: 20px 30px;
  }
  @media screen and (max-width: 959px) {
    .choose-wallet-connect {
      padding: 0;
    }
  }
}
